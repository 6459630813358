
// // import { Loader } from "@googlemaps/js-api-loader";
// // import { MarkerClusterer } from "@googlemaps/markerclusterer";
import Fuse from 'fuse.js';

let markerss = [];
let defaultPosition = { lat: 51.9799701, lng: 5.902362 }
let data = null;
let isFuzzySearchTriggered = false;
let lastSearchString;
let latestMatched = [];
let latestMatchedIds = [];
let searchTerm;
let selectedCategories;
let openInfoWindow = null;
const body = document.querySelector('body');
const baseUrl = '/wp-content/uploads/';
let resultatenDiv;
let all;
// Note: This example requires that you consent to location sharing when
// prompted by your browser. If you see the error "The Geolocation service
// failed.", it means you probably did not give permission for the browser to
// locate you.
let map, infoWindow, markers;
let bounds = null;

function smoothScrollToElement(container, targetY, duration = 500) {
  const startY = container.scrollTop;
  const diff = targetY - startY;
  let startTime = null;

  function scrollStep(timestamp) {
    if (!startTime) startTime = timestamp;
    const progress = timestamp - startTime;
    const normalizedTime = Math.min(progress / duration, 1); // Keep within bounds 0-1
    const easedTime = easeInOutQuad(normalizedTime); // Apply easing function
    container.scrollTop = startY + diff * easedTime;

    if (normalizedTime < 1) {
      requestAnimationFrame(scrollStep);
    }
  }

  requestAnimationFrame(scrollStep);
}

// Easing function for ease-in-out
function easeInOutQuad(t) {
  return t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2;
}

// Usage




const addTemporaryListener = () => {
  const temporaryListener = map.addListener("click", () => {
    document.body.classList.toggle('expand');
    const aanbiederItems = document.querySelectorAll('.aanbieder-item')
    removeAllclasses(aanbiederItems, 'expand');
    google.maps.event.removeListener(temporaryListener); // Remove the listener after it fires
  });
};
function removeAllclasses(ele, eleClass) {
  ele.forEach((item) => {
    item.classList.remove(eleClass);
  })
}

function registerTags(aanbiederItem) {
  
  const tags = document.querySelectorAll('.ilj_disciplines li');
  tags.forEach(item => { 
    item.addEventListener("click", (e) => { 
      
      //filterMarkers(e.target.textContent);???????
      var selectedValues = [
        {
          text: e.target.textContent.toLowerCase(),
          //value: 'dans'
        }
      ];
      loadMap(selectedValues);
      resetTags();
      closePanel();
      const searchInput = document.getElementById('searchInput');
      if (searchInput) {
        searchInput.value = e.target.textContent.toLowerCase();
      }

      e.preventDefault();
    })
  });
  
}

function resetTags() {
  const showMore = document.querySelectorAll('.ilj_disciplines .show-more');
  showMore.forEach(item => { 

    item.style.display = 'inline-block';
    item.nextSibling.style.display = 'none';
  })
}

function closePanel() {
  //document.body.classList.remove('expand');
  const resultaten = document.querySelector('.resultaten');
  if (resultaten) {
    resultaten.classList.remove('open');
  }

}
document.addEventListener("DOMContentLoaded", () => {
  
  resultatenDiv = document.querySelector('.map-wr .resultaten');
  
  /* create list of aanbieders */
  resultatenDiv.innerHTML = makeAanbiederList(locations);
  const aanbiederItem = document.querySelectorAll('.aanbieder-item');
  /* add listeners to tags */
  registerTags(aanbiederItem);
  

  
  
  //all = resultatenDiv.querySelectorAll('a.btn');
  const closeButton = resultatenDiv.querySelector('.close');
  const closeButtonMobile = resultatenDiv.querySelector('.close-m');
  if (closeButton) {
    
    closeButton.addEventListener("click", () => { 
      document.body.classList.remove('expand');
      //const aanbiederItem = document.querySelectorAll('.aanbieder-item')
      removeAllclasses(aanbiederItem, 'expand');
      
    })
  }
  if (closeButtonMobile) {

    closeButtonMobile.addEventListener("click", () => {
      document.body.classList.remove('expand');
      //const aanbiederItem = document.querySelectorAll('.aanbieder-item')
      removeAllclasses(aanbiederItem, 'expand');
      resultatenDiv.classList.remove('open');
      removeAllclasses(aanbiederItem, 'active');

    })
  }
  
  

  aanbiederItem.forEach((item) => {
    let dataId = item.getAttribute('data-id');
    const butt = item.querySelector('a.btn');

    butt.addEventListener("click", (e) => {
      e.preventDefault();
      removeAllclasses(aanbiederItem, 'active');
      let wr = document.querySelector('[data-id="' + dataId + '"]');
      if (!document.body.classList.contains('expand')) {
        document.body.classList.toggle('expand');
      }
      
      wr.classList.toggle('expand');
      wr.classList.toggle('active');

      addTemporaryListener();
      if (wr) {
        let st = setTimeout(() => { 
          //alert('second');
          wr.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "nearest" });
          

        },350);
        
      }
    });

    
  });
 

  //let resultatenDivHeight = resultatenDiv.innerHeight;
  
  innit();
  

});


function innit() {
  const resultatenDivHeight = resultatenDiv.offsetHeight;
  const lastBlock = resultatenDiv.querySelector('ul li:last-child');
  const lastBlockHeight = lastBlock.offsetHeight;
  //console.log(resultatenDivHeight);
  //lastBlock.style.border = 'solid 10px red';
  //resultatenDiv.style.paddingBottom = (resultatenDivHeight - (lastBlockHeight)) + 'px';
  //console.log(lastBlockHeight);
}


function shortenHTML(html, maxLength) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  let currentLength = 0;

  function trimNode(node) {
    if (node.nodeType === Node.TEXT_NODE) {
      const remainingLength = maxLength - currentLength;
      if (node.textContent.length > remainingLength) {
        node.textContent = node.textContent.slice(0, remainingLength) + '...';
        currentLength = maxLength;
      } else {
        currentLength += node.textContent.length;
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      for (let i = 0; i < node.childNodes.length && currentLength < maxLength; i++) {
        trimNode(node.childNodes[i]);
      }
      if (currentLength >= maxLength) {
        while (node.nextSibling) {
          node.parentNode.removeChild(node.nextSibling);
        }
      }
    }
  }

  trimNode(doc.body);
  return doc.body.innerHTML;
}

fetch(baseUrl + 'acf-data.json')
  .then(response => response.json())
  .then(jsonData => {
    data = jsonData;
    //console.log(data);
    ceateMultipleOptionsSelect(data);
  })
  
  .catch(error => {
    console.error('Error loading JSON data:', error);
});

import { MarkerClusterer } from "@googlemaps/markerclusterer";

////console.log(locations);

function removeHtml(str) {
  
  const cleanString = str.replace(/<[^>]*>/g, "");
  //alert(cleanString);
  return cleanString;
}


function makeAanbiederList(locations) {
  let output = '';
  let i = 0;
  //console.log(locations);
  locations.forEach((location) => { 
    //console.log(location.disciplines);
    //const html = shortenHTML(location.info.intro_tekst,160);
    
    const html = location.info.intro_tekst;
    
    output += '<li class="aanbieder-item" data-id="'+location.info.id + '">';
      output += '<h2>' + location.info.name + '</h2>';
      output += makeTagList(location.disciplines, selectedCategories);
      output += '<div class="aanbieder-profile">';
      output += html;
      output += '</div>';
      output += '<div class="aanbieder-info">';
        output += '<a class="p-1 ps-0 pe-2 email" href="mailto:' + location.info.email + '?subject=Aanvraag%20via%20ikleer.je">email</a>';
        output += '<a class="p-1 ps-0 pe-2 email" target="_blank" href="' + location.info.website + '">website</a>';
        output += '<a class="p-1 ps-0 pe-2 phone" href="tel:' + location.info.telefoon + '">' + location.info.telefoon + '</a>';
    output += '</div>';
    
      output += '<div class="aanbieder-lokatie">';
      location.locations.forEach((location) => { 
        output += '<div class="locatie">';
          output += '<div>' + location.adres +'</div>';
          output += '<div>' + location.postcode + ' ' + location.woonplaats +'</div>';
        output += '</div>';
      
      });
    output += '</div>';
    
   
   
    output += '<a class="btn btn-primary mt-3">Meer info</a>';
    output += '</li>';
    
  })
  return '<a class=" close">x</a><a class=" close-m ">x</a><ul class="aanbieders-list">'+output+'</ul>';
  
}
function highlightAllTerms(term) {
  document.querySelectorAll(".ilj_disciplines").forEach((container) => {
    highlightTerm(container, term);
  });
}

function highlightTerm(container, term) {
  const listItems = Array.from(container.querySelectorAll("li"));
  let showMoreLink = container.querySelector(".show-more");

  // Only add a single "Show less" link per container
  let showLessLink = container.querySelector(".show-less");
  if (!showLessLink) {
    showLessLink = document.createElement("a");
    showLessLink.href = "#";
    showLessLink.textContent = "Toon minder";
    showLessLink.classList.add("show-less");
    showLessLink.style.display = "none"; // Hide "Show less" initially
   // container.appendChild(showLessLink);
    showMoreLink.insertAdjacentElement('afterend', showLessLink);
  }

  const lowerTerm = term.toLowerCase();
  let matchedItem = null;

  listItems.forEach((li) => {
    const anchor = li.querySelector("a");
    li.classList.remove("hl","hidden");
    
    if (anchor && anchor.textContent.toLowerCase() === lowerTerm) {
      li.classList.add("hl");
      matchedItem = li;
    } else {
      li.classList.add('hidden'); // Hide non-matching items initially
    }
  });

  if (matchedItem) {
    container.querySelector("ul").insertBefore(matchedItem, container.querySelector("ul").firstChild); // Move matched item to the front
  }

  // Show more functionality
  showMoreLink.addEventListener("click", function (event) {
    
    event.preventDefault();
    listItems.forEach((li) => {
      li.classList.remove('hidden'); // Show all items
    });
    showMoreLink.style.display = "none"; // Hide "Read more" link
    showLessLink.style.display = ""; // Show "Show less" link
  });

  // Show less functionality
  showLessLink.addEventListener("click", function (event) {
    event.preventDefault();
    listItems.forEach((li) => {
      if (!li.classList.contains("hl")) {
        li.classList.add("hidden"); // Hide non-highlighted items again
      }
    });
    showLessLink.style.display = "none"; // Hide "Show less" link
    showMoreLink.style.display = ""; // Show "Read more" link
  });
}





// function selecTags(selectedCategories) {
//   // console.log('-----------------');
//   // console.log('selectedCategories', selectedCategories);
//   all.forEach((item) => { 
//     item.classList.remove('hl');
//     let discipline = item.querySelector('a').textContent.toLowerCase();
//     //console.log(discipline);
//     if (discipline == selectedCategories) {
//       item.classList.add('hl');
//     }
//   });
// }

function makeTagList(disciplines, selectedCategories) {
  
  let output = '<div class="ilj_disciplines">';
  output += '<ul>';

  disciplines.forEach((discipline, index) => {
    output += '<li class="hidden"><a href="' + discipline + '">' + discipline + '</a></li>';
  });

  output += '</ul>';
  let classX = '';
  if (disciplines.length <= 1) {
    classX = 'hidden';
  } 
  output += '<a href="#" class="show-more ' + classX + '">+</a>';
  output += '<a href="#" class="show-less " style="display: none;">-</a>';
  output += '</div>';

  
  return output;
}


function showAanbieder(id) {
  //alert(id);
  //console.log('id',id);
  // show bar
  resultatenDiv.classList.add('open');
  // scroll to posiiton
  const targetElement = resultatenDiv.querySelector(`[data-id="${id}"]`);
  const aanbiederItems = resultatenDiv.querySelectorAll(`[data-id]`);
  const pos =targetElement.offsetTop - resultatenDiv.offsetTop;

  // Usage:
  //const container = document.querySelector('.overflow-div'); // Replace with your div selector
  // smoothScrollToElement(resultatenDiv, pos, 550); // Adjust target scroll position (500) and duration (150ms)
  //center the map around the result
  
  // make item active
  
  
  aanbiederItems.forEach((item) => { 
    //console.log('item',item);
    item.classList.remove('active');
    
  });
  targetElement.classList.add('active');

  let st = setTimeout(() => { 
    targetElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  },350);
  
}




async function initMap() {
  //console.log('initMap');
  const { Map, InfoWindow } = await google.maps.importLibrary("maps");
  const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary(
    "marker",
  );
  const infoWindow = new InfoWindow({ pixelOffset: new google.maps.Size(0, -30) });
  bounds = new google.maps.LatLngBounds();
  map = new google.maps.Map(document.getElementById("map"), {
    center: { lat: 51.9799612, lng: 5.9023701 },
    zoom: 14,
    mapId: "9c779b60d22c2f32",
    gestureHandling: "cooperative",
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false
   // scrollwheel: false
  });
  google.maps.event.addListener(map, 'bounds_changed', function () {
    // Set maximum zoom after the initial bounds adjustment
    if (map.getZoom() > 17) {  // Choose an appropriate maximum zoom level
      map.setZoom(17);          // For instance, zoom level 15 or any maximum that suits your need
      ////console.log('did it');
    }
  });
  
  const pin = new PinElement({
    scale: 1.85,
    glyph: "T",
    glyphColor: "yellow",
    background: "#FBBC04",
    borderColor: "#FBBC04",
  });

  const priceTag = document.createElement("div");
  
  let counter = 0;
  //console.table(locations);
  locations.forEach((element, index1) => {
    
    
    
    let name = element.name;
    map.addListener("click", () => {
      infoWindow.close();
    });
    
    element.locations.forEach((location, index) => {
      if (location.lat || location.lon) {
        ////console.log(element.name+'i'+index);
        markerss[counter] = {
          marker: new google.maps.marker.AdvancedMarkerElement({
            map,
            position: { lat: parseFloat(location.lat), lng: parseFloat(location.lon) },
            //content: PinElement.element,
            title: name,
            gmpClickable: true,
          }),
          
          //categories: ["discipline", "facilitair", "nogeentje"], // Example categories
          categories: element.disciplines,
          info: element.info
        };
        latestMatchedIds.push(element.info.id);
        markerss[counter].marker.addListener("click", ((counter) => ({ domEvent, latLng }) => {
          const { target } = domEvent;
          ////console.log(counter);
          ////console.log('addEventListener');
          
          
          
          //infoWindow.setContent(decodedContent);
          let id = markerss[counter].info.id;
          let name = markerss[counter].info.name;
          let email = markerss[counter].info.email;
          let telefoon = markerss[counter].info.telefoon;
          let cat = markerss[counter].categories;
          let intro_tekst = markerss[counter].info.intro_tekst;
          let website = markerss[counter].info.website;
          
          let content = '<h2>' + name + '</h2>';
          content += '<a href="/wp-admin/post.php?post=' + id + '&action=edit">edit</a>';
          //content += intro_tekst;
          // content += website+'<br />';
          // content += telefoon + '<br />';
          
          // content += email + '<br />';
          // content += cat + '<br />'; 
         
          infoWindow.close();
          infoWindow.setContent(content);
          infoWindow.setPosition({ lat: parseFloat(location.lat), lng: parseFloat(location.lon) });
          showAanbieder(id);
          
          infoWindow.open(map);
          openInfoWindow = infoWindow;
        })(counter));
        
        ////console.log(element.disciplines);
        markerss[counter].marker.map = map;
        
      }
      
      ////console.log(counter);
      counter++;
    });
   
    
    //////console.log(markerss[index].marker.position);
    //highlightAllTerms('');
    
  });

  priceTag.className = "price-tag";
  priceTag.textContent = "$2.5M";

  
  
  /* GEO STUFF */
  const locationButton = document.createElement("button");
  locationButton.textContent = "Mijn locatie";
  locationButton.classList.add("custom-map-control-button", "btn","btn-primary");
  let mapsPrefsWr = document.querySelector('.maps-prefs-wr .maps-prefs');
  mapsPrefsWr.appendChild(locationButton);
  //map.controls[google.maps.ControlPosition.TOP_LEFT].push(locationButton);
  locationButton.addEventListener("click", () => {
    locationButton.classList.add('jump-ani');
    // Try HTML5 geolocation.
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          infoWindow.setPosition(pos);
          infoWindow.setContent("Ah! Daar ben je ;-)");
          infoWindow.open(map);
          map.setZoom(19);
          map.setCenter(pos);
          locationButton.classList.remove('jump-ani');
          
        },
        () => {
         
          handleLocationError(true, infoWindow, map.getCenter());
        },
      );
    } else {
      // Browser doesn't support Geolocation
      locationButton.classList.remove('jump-ani');
      handleLocationError(false, infoWindow, map.getCenter());
    }
  });
  ShowLatestMatchedIds(latestMatchedIds);
}



function ShowLatestMatchedIds(ids) {
  const aanbiederItem = resultatenDiv.querySelectorAll('.aanbieder-item');
  aanbiederItem.forEach((item) => { 
    item.classList.remove('show');
  });
  ids.forEach((id) => { 
    
    let item = resultatenDiv.querySelector(`[data-id="${id}"]`);
    //item.style.border = 'solid red 10px';
    item.classList.add('show');
    resultatenDiv.scrollTop = 0;
   
  });
  innit();
  
}



function handleLocationError(browserHasGeolocation, infoWindow, pos) {
  infoWindow.setPosition(pos);
  infoWindow.setContent(
    browserHasGeolocation
      ? "Error: The Geolocation service failed."
      : "Error: Your browser doesn't support geolocation.",
  );
  infoWindow.open(map);
}

function filterMarkers(selectedCategories) {
  //console.log('selectedCategories',selectedCategories);
  
  let matches = false;
  let isMatch;
  latestMatched = [];
  latestMatchedIds = [];
  markerss.forEach(({ marker, categories, info },index) => {
    
    // Check if any of the marker's categories match the selected categories
    if (categories) {
      //isMatch = categories.some(category => selectedCategories.includes(category.toLowerCase()));
      //console.log(categories);
      isMatch = categories.some((category) => selectedCategories == category.toLowerCase());
      if (isMatch) console.log(selectedCategories, isMatch);
      //console.log('is mtch cat', isMatch, categories[index]);
    }
    if (info.name && !isMatch) {
      //isMatch = selectedCategories.includes(info.name.toLowerCase()); //matches also parts of the sting
      

      isMatch = selectedCategories === info.name.toLowerCase();
      if (isMatch) console.log(selectedCategories, info.name.toLowerCase(), isMatch);
      //console.log('is mtch info', isMatch, info);
      ////console.log('true')
    }
    ////console.log(info.name);
    
      // add visibility based on match
      if (isMatch) {
        marker.map = map;
        latestMatched.push(marker);
        latestMatchedIds.push(info.id);
        // ////console.log(marker.position);
        bounds.extend(marker.position);
        matches = true;
        
      } else {
        marker.map = null
        //matches = false;
        
      }
    ShowLatestMatchedIds(latestMatchedIds);
    document.body.classList.remove('expand');
    if (openInfoWindow) {
      openInfoWindow.close();
    }
    
    //selecTags(selectedCategories);
    
    
     
    
  });
  
  
  highlightAllTerms(selectedCategories);
  
  if (!matches) {
   
    return false;
    //console.log('No-Direct-Match');&& !isFuzzySearchTriggered
    //performFuzzySearch(selectedCategories[0]);
    //isFuzzySearchTriggered = true;
    
  } else {
    map.fitBounds(bounds);
    bounds = new google.maps.LatLngBounds();
    isFuzzySearchTriggered = false;
    return true;
  }
  // if (matches) {
  //  // ////console.log('Ja hoor er is reulstaat');
  // } else {
  //  // ////console.log('Wel een match in de data maar geen resultaat(verschil tussen input array en locaties)');
  // }
  // Reset the fuzzy search trigger after performing a search
  isFuzzySearchTriggered = false;
  
}


function performFuzzySearch(searchTerm) {
  // do fuzzy stuff
  const options = {
    keys: [
      { name: "disciplines", weight: 0.7 },
      { name: "info.name", weight: 0.3 },
      //{ name: "info.intro_tekst", weight: 0.4 }
    ],
    includeScore: true,
    includeMatches: true,
    threshold: 0.3,
    shouldSort: true
  };

  // Initialize Fuse
  const fuse = new Fuse(locations, options);
  // Perform the search

  if (searchTerm) {
    
    const results = fuse.search(searchTerm);

    // Process results to include matched category items and calculate total score
    const processedResults = results.map(result => {
      // Extract matched categories and values, calculating an adjusted score
      const matchedCategories = result.matches.map(match => ({
        category: match.key,
        matchedValue: match.value,
        score: result.score // Each match retains the result's overall score
      }));

      // Sort matches by prioritizing `info.name` and `info.intro_tekst` over `disciplines`
      matchedCategories.sort((a, b) => {
        const weights = { "info.name": 0.3, "info.intro_tekst": 0.3, "disciplines": 0.4 };
        return weights[b.category] - weights[a.category];
      });

      // Calculate the total score as the average of individual match scores
      const totalScore = matchedCategories.reduce((acc, curr) => acc + curr.score, 0) / matchedCategories.length;

      return {
        item: result.item,
        totalScore: totalScore,
        matchedCategories: matchedCategories
      };
    });

    // Display processed results with matched category items and total score
    processedResults.forEach(result => {
      console.log("Total Score:", result.totalScore);
      console.log("Matched Categories with Items (sorted):", result.matchedCategories);
      console.log("Item:", result.item);
    });

    if (processedResults[0]?.matchedCategories[0]?.matchedValue) {
      console.log('DE BESTE MATCH! ', removeHtml(processedResults[0].matchedCategories[0].matchedValue));
      let fuzzySeacgString = removeHtml(processedResults[0].matchedCategories[0].matchedValue.toLowerCase());
      const fuzzyResult = [fuzzySeacgString, processedResults[0]];
      return fuzzyResult;

      let score = processedResults[0].matchedCategories[0].score;
      //console.log('De score =', score);
      var selectedValues = [
        {
          text: processedResults[0].matchedCategories[0].matchedValue,
        }
      ];

      let msg;
      msg = 'Niets gevonden... Bedoelde je: <strong>' + processedResults[0].matchedCategories[0].matchedValue + '</strong>';
      //console.log(lastSearchString, fuzzySeacgString);
      if (lastSearchString == fuzzySeacgString) {
        //console.log('De uitkomst van de fuzzy seach is dat ie gelijk is.');
        //console.log(latestMatched);
        // latestMatched.forEach(marker => { 
        //   //console.log(marker);
        //   marker.map = map;
        // });
        //latestMatched = [];
        //return;
      }else{
      
        // loadMap(selectedValues);
        // latestMatched = [];
      }
      //showSearchMessage(msg);
      


    } else {
      //console.log('Ook niets wat er op lijkt');
      return false;
      showSearchMessage('We hebben niets gevonden 🙁');
    }
  } else {
    return false;
  }
  isFuzzySearchTriggered = false;
  noResult();
}
function showSearchMessage(msg) {
  body.classList.add('show-msg-map');
  const msgDiv = document.querySelector('.did-you-mean-fuse-map');
  msgDiv.innerHTML = '';
  msgDiv.innerHTML = msg;
  const so = setTimeout(() => { 
    body.classList.remove('show-msg-map');
    msgDiv.innerHTML = '';
  },5000);
}

function getSelectedValues(select) {
  const selectedValues = Array.from(select.options)
    .filter(option => option.selected)
    .map(option => option.value);

 // ////console.log(selectedValues); // Output the selected values
  return selectedValues;       // Optionally, return or use this array as needed
}

function ceateMultipleOptionsSelect(data) {
  //console.table(data);
  let outputOptions = null;
  data.forEach((data_item, index) => {
    outputOptions += '<option value="' + data_item + '">' + data_item + '</option>';
  });
  const to = setTimeout(() => {
    select.innerHTML = outputOptions;
   // ////console.log('fire int he dusco!');
   },0)
  
}


document.addEventListener("DOMContentLoaded", () => { 

  let select = document.querySelector('.cb');
  select.addEventListener("change", () => {
    ////console.log('options event chang fired!');
    let sVals = getSelectedValues(select);
    
    filterMarkers(sVals);
    
    

  })

  
    
})




initMap();

const searchInput = document.getElementById('searchInput');
const select = document.getElementById('mySelect');
const resultsContainer = document.getElementById('resultsContainer');
//const noOptions = document.getElementById('noOptions');
let typingTimer; // Timer identifier for map loading
const mapDelay = 350; // Delay in milliseconds for map loading
let currentFocusIndex = -1; // Index for tracking focused item

// Populate and show filtered options
function showFilteredOptions() {
  resultsContainer.innerHTML = ''; // Clear existing results
  const searchValue = searchInput.value;
  const options = select.querySelectorAll('option');
  let hasVisibleOption = false;
  const filteredOptions = []; // Array to store filtered options
  if (searchInput.value.trim() === '') {
    // Hide options if input is empty
    resultsContainer.innerHTML = ''; // Clear existing results
    resultsContainer.style.display = 'none';
    //noOptions.style.display = 'none';a
    ////console.log('LEEG!');
    return;
  }
  options.forEach(option => {
    if (option.text.toLowerCase().includes(searchInput.value.toLowerCase())) {
      hasVisibleOption = true; // Track that there's at least one match
      const optionText = option.text.toLowerCase();
      
      // Create a div for each matching option
      const optionDiv = document.createElement('div');
      optionDiv.textContent = option.text;
      optionDiv.classList.add('result-option');
      optionDiv.dataset.value = option.value;

      


      // Highlight the found substring
      const startIndex = optionText.indexOf(searchValue);
      const highlightedText =`${option.text.substring(0, startIndex)}<span class="highlight">${option.text.substring(startIndex, startIndex + searchValue.length)}</span>${option.text.substring(startIndex + searchValue.length)}`;
      optionDiv.innerHTML = highlightedText.trim();
      

      // Mark the option as selected in the hidden <select>
      option.selected = false;
      //optionDiv.classList.add('selected-option');

      // Add the matching option to the filtered options array
      filteredOptions.push(option);

      // Add click event for selection
      optionDiv.addEventListener('click', function (e) {
        //option.selected = !option.selected; // Toggle selection
        //optionDiv.classList.add('selected-option', option.selected);
        //select.dispatchEvent(new Event('change', { bubbles: true }));
        const selectedTarget = e.target;
        let clickedOption;
        if (selectedTarget.classList.contains('highlight')) {
           clickedOption = e.target.parentNode.getAttribute('data-value').toLowerCase();
        } else {
           clickedOption = e.target.getAttribute('data-value').toLowerCase();  
        }
        
        //const selectedValues = Array.from(select.selectedOptions).map(opt => opt.value);

        //////console.log('Selected values:', selectedValues);
        var selectedValues = [
          {
            text: clickedOption,
            //value: 'dans'
          }
        ];

        

        loadMap(selectedValues);
        closeOptionPulldown();
        e.preventDefault();
      });

      resultsContainer.appendChild(optionDiv);
    } else {
      option.selected = false; // Deselect if not matching
      //////console.log('Geen resultaat!!!!!');
    }
  });

  // Show or hide "No options found" message
  // noOptions.style.display = hasVisibleOption ? 'none' : 'block';
  ////console.log(hasVisibleOption);
  //defaultMapsPosition();

  // Set a timer for map loading delay
  
  // clearTimeout(typingTimer);
  // typingTimer = setTimeout(() => {
  //   if (hasVisibleOption) {

  //     ////console.log('load from timeout');
  //     ////console.log
  //     loadMap(filteredOptions); // Call the map loading function with filtered options after a delay
     
  //   } else {
  //     defaultMapsPosition();
  //   }
  // }, mapDelay);
}

// Mock map loading function that takes filtered options as a parameter

function enToAmps(str) {
  let updatedText = str.replace(/&/g, "&amp;");
  return updatedText;  // Output: piano &amp; toetsen
}

function loadMap(filteredOptions) {
  console.log('loadMapfilteredOptions', filteredOptions);
  searchTerm = enToAmps(filteredOptions[0].text.toLowerCase());
  console.log('searchTerm', searchTerm);
  let msg;
  //check if same search
  if (searchTerm === lastSearchString) {
    //alert('!');
    console.log('!!!!!!!!!!!!!!!heb je al gezocht.');
    return;
  
  } else {
    //////console.log('Map is loaded with the following options after 350ms:', filteredOptions.map(opt => opt.text));
    console.log('_-----_DOI!', selectedCategories)
    if (selectedCategories) {
      highlightAllTerms(selectedCategories);
    } 
    const showTheFoundMatchedMarkers = filterMarkers(searchTerm);
    closePanel();
    console.log('closePanel();');
    if (!showTheFoundMatchedMarkers) {
      console.log('--------------------------------');
      console.log('Geen resultaat do a Fuzzy search');
      console.log('--------------------------------');
      const fuzzySesult = performFuzzySearch(searchTerm);
      if (fuzzySesult) {
        console.log('FuzzySesult', fuzzySesult);
        msg = 'Niets gevonden... Bedoelde je: <strong>' + fuzzySesult[0] + '</strong>';
        showSearchMessage(msg);
        const withFuzzyResult = filterMarkers(fuzzySesult[0]);
        if (!withFuzzyResult) {
          console.log(fuzzySesult[1])
          console.log('-------------------------------~~~~~~~~~-');
          console.log('Gezocht met Fuzzy result en niet gevonden');
          console.log('-------------------------------~~~~~~~~~-');
          showSearchMessage('We hebben niets gevonden 🙁');
        }
      } else {
        console.log('geen FuzzySesult');
        showSearchMessage('We hebben niets gevonden 🙁');
      }
    } else {
      console.log('---------------');
      console.log('Wel resultaat exit.');
      console.log('---------------');
    }
  }
  lastSearchString = filteredOptions[0].text;
  //console.log('latestMatchedIds', latestMatchedIds);
  // Replace this with your map update logic using `filteredOptions`
}

// Handle keyboard navigation for autocomplete
function handleKeyboardNavigation(e) {
  console.log('handleKeyboardNavigation');
  const optionDivs = resultsContainer.querySelectorAll('.result-option');

  if (e.key === 'ArrowDown') {
    e.preventDefault();
    currentFocusIndex++;
    if (currentFocusIndex >= optionDivs.length) currentFocusIndex = 0;
    setActiveOption(optionDivs);
  } else if (e.key === 'ArrowUp') {
    e.preventDefault();
    currentFocusIndex--;
    if (currentFocusIndex < 0) currentFocusIndex = optionDivs.length - 1;
    setActiveOption(optionDivs);
  } else if (e.key === 'Enter') {
    e.preventDefault();
    
  }
}

function closeOptionPulldown() {
  const optionPullDown = document.getElementById('resultsContainer');
  optionPullDown.innerHTML = '';
}


// Set active option by adding a focus class
function setActiveOption(optionDivs) {
  optionDivs.forEach((div, index) => {
    div.classList.remove('focused-option');
    if (index === currentFocusIndex) {
      div.classList.add('focused-option');
      const focusValue = div.getAttribute('data-value');
      searchInput.value = focusValue;
      // var event = new Event('input');
      // searchInput.dispatchEvent(event);
      div.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "nearest" }); // Ensure focused item is in view
    }
  });
}

// Filter options on input without delay for displaying options
searchInput.addEventListener('input', function (e) { //was keydown
  //if (e.key === 'Enter') {
    if (searchInput.value != '') {
      showFilteredOptions(); // Immediately show filtered options
      //////console.log('wel inhoud');
    } else {
      console.log('geen inhoud');
      resultsContainer.innerHTML = '';
      defaultMapsPosition();
      
      
    }
  //}
});
searchInput.addEventListener('keydown', function (e) {
  if (e.key === 'Enter') {
    //console.log('enterHit');
    if (searchInput.value != '') {
      var selectedValues = [
        {
          text: searchInput.value.toLowerCase(),
          //value: 'dans'
        }
      ];
      loadMap(selectedValues);
      closeOptionPulldown();
      //////console.log('wel inhoud');
    } else {
      //////console.log('geen inhoud');
      resultsContainer.innerHTML = '';
      defaultMapsPosition();


    }
  }
});
// Delegate event from button click
const submitButton = document.getElementById('map-submit');
submitButton.addEventListener('click', function () {
  const enterKeyEvent = new KeyboardEvent('keydown', { key: 'Enter' });
  searchInput.dispatchEvent(enterKeyEvent);
});

function showAllMarkers() {
  console.log('showAllMarkers');
  latestMatchedIds = [];
  markerss.forEach(({ marker, categories,info }) => {
    
    // Check if any of the marker's categories match the selected categories
    //const isMatch = categories.some(category => selectedCategories.includes(category.toLowerCase()));
    latestMatchedIds.push(info.id);
    // add visibility based on match
    
    marker.map = map;
  });
}
function noResult() {
  //console.log('geen resultaat!');
}
function defaultMapsPosition() {
  //console.log('defaultMapsPosition()');
  ////console.log(defaultPosition);
  let so = setTimeout(() => {
    map.setCenter(defaultPosition);
    map.setZoom(13);
    showAllMarkers();
    ShowLatestMatchedIds(latestMatchedIds);
   },450);
}

// Start with no options visible (empty the container)
resultsContainer.innerHTML = '';
//noOptions.style.display = 'none';

// Handle keyboard events for the input field
searchInput.addEventListener('keydown', handleKeyboardNavigation);

// Detect when fullscreen mode is entered or exited
document.addEventListener("fullscreenchange", () => {
  const overlay = document.querySelector(".map-search-input");
  if (document.fullscreenElement) {
    // If map is fullscreen, add the overlay back to keep it on top
    //alert('FC');
    overlay.style.position = "fixed";  // Ensure it stays on top
  } else {
    // Reset overlay positioning when fullscreen exits
    overlay.style.position = "absolute";
  }
});